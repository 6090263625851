<template>
  <div class="row">
    <div class="col"></div>
    <div class="col-3">
      <div>Copyright Copyright Copyright</div>
    </div>
    <div class="col"></div>
  </div>
</template>

<style scoped></style>

<script>
export default {
  name: 'Footer',
}
</script>
