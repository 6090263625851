<template>
  <div>
    <div class="row">Home</div>
  </div>
</template>

<style scoped></style>

<script>
export default {
  name: 'Home',
}
</script>
