<template>
    <div>
    <Breadcrumb />
    <Header />
    <div>
        <h1 class="py-6" style="text-align: center;">Student Help</h1>
        <div class="py-6" style="text-align: center;">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Pariatur ipsa voluptate repellat ratione. Dolore, repellat beatae magnam explicabo quam voluptates omnis molestias quae aliquid quaerat, distinctio, fugit nisi necessitatibus laborum!
        </div>
    </div>
</div>
</template>

<style scoped>

</style>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
export default {
    setup() {

    },
    components:{
        Breadcrumb,
        Header,
    }
}
</script>