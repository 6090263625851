<template>
<div>Take Exam</div>
</template>

<style scoped>

</style>

<script>
export default {
    setup() {

    },
}
</script>